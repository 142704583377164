import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/Layout"

export default function NotFound() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 — Schülerwohnheim Forster GmbH</title>
      </Helmet>
      <h1>404 - Not found</h1>
      <p>Diese Seite existiert nicht.</p>
    </Layout>
  )
}
